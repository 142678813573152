import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent {

  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;


 img_url=appkeys.retriveimgUrl + ''
serviceUrl=appkeys.retriveimgUrl
isSpinning = false;
Videocount:any=[]
apiLoaded = false;
Albumcount:any=[]
videos:any;
  albums:any;
  videoId = 'QIZ9aZD6vs0';


  @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
  {
	this.scrHeight = window.innerHeight; 
	this.scrWidth = window.innerWidth;
	 console.log(this.scrHeight, this.scrWidth);
  }
  constructor(private api:ApiService) {
    
		this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }

  ngOnInit(): void {
    this.api.getallVideoAlbums(0,0,'SEQUENCE_NO','asc','AND STATUS=1 ')
    .subscribe(data => {
      this.Albumcount=data['count']
      if (data['code'] == 200 )
        this.albums = data['data']
        var ID=data['data'][0]['ID']
        if(this.albums.length>0)
        {
          this.api.getVideoMaster(0,0,'SEQUENCE_NO','asc','AND STATUS=1')
          .subscribe(data => {
            this.Videocount=data['count']
            if (data['code'] == 200 )
              this.videos = data['data']
              // this.isSpinning=false;
          })
        }
    })

    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }

  }

  get(event:any)
{
  this.api.getVideoMaster(0,0,'','asc','AND STATUS=1 AND VIDEO_ALBUM_ID= '+event)
  .subscribe(data => {
    this.Videocount=data['count']
    if (data['code'] == 200)
      this.videos = data['data']
      // this.isSpinning=false;
  })
  this.videos=[];
}

  showcolor:any;
  getI(event:any){
    this.showcolor=event;
    this.showcolor1=0
  }

  showcolor1=1

  currentPlayingVideo: HTMLVideoElement;

  onPlayingVideo(event:any) {
    event.preventDefault();
    // play the first video that is chosen by the user
    if (this.currentPlayingVideo === undefined) {
        this.currentPlayingVideo = event.target;
        this.currentPlayingVideo.play();
    } else {
    // if the user plays a new video, pause the last 
    // one and play the new one
        if (event.target !== this.currentPlayingVideo) {
            this.currentPlayingVideo.pause();
            this.currentPlayingVideo = event.target;
            this.currentPlayingVideo.play();
        }
    }
}

getall()
{
  this.api.getVideoMaster(0,0,'','asc','AND STATUS=1')
  .subscribe(data => {
    this.Videocount=data['count']
    if (data['code'] == 200)
      this.videos = data['data']
     
      this.showcolor=null
      this.showcolor1=1
  })
}
 
}
