<!-- Page banner Area -->
<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Director.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="    margin: 60px 172px 60px 0px;
                padding: 7px 0px;
                min-width: 140px;
                max-width: 20px;">
                    <h2 style="font-size: 12PX;
                    margin-bottom: 0px;">Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/home-three" style="font-size:9px;">Home</a></li>
                        <li style="font-size:9px;">Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Director.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Our Expert Team</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Team Area -->
<div class="team-area pt-100 pb-70" style="padding-top: 50px;">
    <div class="container">
        <div class="section-title">
            <span>Our Team</span>
            <h3>Intelligence Heros Make The Company Proud</h3>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let team of TeamsData">
                <div class="team-card">
                    <div class="team-image">
                        <img *ngIf="TeamsData!=undefined" src="{{serviceUrl}}commiteImage/{{team.IMG_URL}}"
                        height="300" width="366" alt="image">

                        <div class="caption">
                        
                        </div>
                    </div>

                    <div class="team-text">
                        <h3 *ngIf="TeamsData!=undefined">{{team.TITLE}}{{team.NAME}}</h3>
                        <span *ngIf="TeamsData!=undefined">{{team.DESIGNATION}}</span>
                        <div *ngIf="TeamsData!=undefined" class="mb-20 limit" style=" min-height: 140px" [innerHTML]="team.DESCRIPTION"></div>
                         <a (click)="getIdOfTeams(team.ID)" routerLink="/teams-details"  class="default-btn-two"
                        style="padding: 5px 8px;">Read More</a>
                    </div>
                </div>
            </div>
        
            

            <!-- <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team2.jpg" alt="image">

                        <div class="caption">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="team-text">
                        <h3>Pery Henry</h3>
                        <span>Marketing Manager</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team3.jpg" alt="image">

                        <div class="caption">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="team-text">
                        <h3>Milthon Hence</h3>
                        <span>Shipping Manager</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team4.jpg" alt="image">

                        <div class="caption">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="team-text">
                        <h3>Hillary Fin</h3>
                        <span>Marketing Specialist</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team5.jpg" alt="image">

                        <div class="caption">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="team-text">
                        <h3>Karlos Bentham</h3>
                        <span>Marking Assistant</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-image">
                        <img src="assets/img/team/team6.jpg" alt="image">

                        <div class="caption">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="team-text">
                        <h3>Niccy Priti</h3>
                        <span>Office Manager</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Team Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->