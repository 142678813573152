import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  year: any;
  constructor(public apiService:ApiService) { }

  ContactData:any=[];
  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
    this.getContactInfo();
  }

  getContactInfo() {
    this.apiService.getContactInfo(1, 2, '', 'desc', '')
      .subscribe(data => {
        this.ContactData = data['data'][0]
        console.log(this.ContactData,"dhbcj")
      })
  }

}
