import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';
// declare function viewLoad():any

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {

  webbanner: any[] = [];
  BannerCount: any = [];
  AboutData: any = []
  ServicesData: any[] = []
  img_url = appkeys.retriveimgUrl + 'websiteBanner/'
  serviceUrl = appkeys.retriveimgUrl

  SERVER_URL = appkeys.retriveimgUrl;
  bannershow = false;


  BlogData: any = [];
  blogUrl = appkeys.retriveimgUrl

  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
    this.bannershow = false;
    this.getWebsiteBannerPages();
    this.getAboutHome();
    this.getServisesData();
    this.getBlogData();
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: { 0: { items: 1 } },
    nav: false
  }



  ServiceOption: OwlOptions = {
    loop: true,
    margin: 30,
    dots: false,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>"
    ],
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 400,
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 3
      },
      1200: {
        items: 3
      }
    }
  }


  BlogOption: OwlOptions = {

    loop: false,
    margin: 30,
    dots: false,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>"
    ],
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 400,
    autoWidth: true,
    // responsive: {
    //   0: {
    //     items: 1
    //   },
    //   576: {
    //     items: 1
    //   },
    //   768: {
    //     items: 3
    //   },
    //   1200: {
    //     items: 3
    //   }
    // }
  }

  //Website Banner Get
  getWebsiteBannerPages() {

    this.webbanner = []
    this.apiService.getAllwebsiteBanner(0, 0, '', 'desc', ' AND STATUS=1')
      .subscribe(data => {
        this.webbanner = data['data']
        this.bannershow = true;
        this.BannerCount = data['count']
        setTimeout(() => {

          this.customOptions = {
            loop: true,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
            dots: true,
            autoplay: true,
            navSpeed: 400,
            navText: ['', ''],

            responsive: { 0: { items: 1 } }, nav: false
          }
        }, 500);
        console.log(this.webbanner, "dhbcj")
      })
  }



  getAboutHome() {
    this.apiService.getAbout(0, 0, '', 'desc', '')
      .subscribe(data => {
        this.AboutData = data['data'][0]
        console.log(this.AboutData, "dhbcj")
      })
  }


  Is_ServiceShowcards: boolean;
  Is_ServiceShowcards1: boolean;
  Is_blogShow: boolean;
  Is_blogShow1: boolean;

  getServisesData() {
    this.apiService.getServices(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        this.ServicesData = data['data']
        if(this.ServicesData.length < 3){
          this.Is_ServiceShowcards = true;
          this.Is_ServiceShowcards1 = false;
        }
        else{
          this.Is_ServiceShowcards1 = true;
          this.Is_ServiceShowcards = false;
        }
        setTimeout(() => {
          if(data['count']>=2){
            this.ServiceOption = {
              loop: (data['data'].length>3) ? true: false,
              margin: 30,
              dots: false,
              nav: true,
              autoplay: true,
              autoplayHoverPause: true,
              navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
              ],
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
              navSpeed: 400,
              responsive: {
                0: {
                  items: 1
                },
                576: {
                  items: 1
                },
                768: {
                  items: 3
                },
                1200: {
                  items: 3
                }
              }
            }
          }else{
            this.ServiceOption = {
              loop: (data['data'].length>3) ? true: false,
              margin: 30,
              dots: false,
              nav: true,
              autoplay: true,
              autoplayHoverPause: true,
              navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
              ],
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
              navSpeed: 400,
            }
          }
        }, 500);
        console.log(this.ServicesData, "dhbcj")
      })
  }

  getBlogData() {
    this.apiService.getNewsAndUpdates(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        this.BlogData = data['data']
        console.log(this.BlogData, "Blogs")

        console.log(this.BlogData.length, "length");
        if(this.BlogData.length < 3){
          console.log("one");
          
          this.Is_blogShow = true;
          this.Is_blogShow1 = false;

        }
        else{
          console.log("two");

          this.Is_blogShow1 = true;
          this.Is_blogShow = false;
        }
        setTimeout(() => {

          if(data['count']>=2){
            this.BlogOption = {
       
              loop:(data['data'].length>3) ? true: false,
              margin: 30,
              dots: false,
              nav: true,
              autoplay: true,
              autoplayHoverPause: true,
              navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
              ],
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
              navSpeed: 400,
              autoWidth: true,
              responsive: {
                0: {
                  items: 1
                },
                576: {
                  items: 1
                },
                768: {
                  items: 3
                },
                1200: {
                  items: 3
                }
              }
            }
          }else{
            this.BlogOption = {
       
              loop:(data['data'].length>3) ? true: false,
              margin: 30,
              dots: false,
              nav: true,
              autoplay: true,
              autoplayHoverPause: true,
              navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
              ],
              mouseDrag: true,
              touchDrag: true,
              pullDrag: true,
              navSpeed: 400,
              autoWidth: true,
            }
          }
        }, 500);
        console.log(this.ServicesData, "dhbcj")
        // this.loaded = true;
      })
  }

  // getServisesData() {
  //   this.apiService.getServices(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
  //     .subscribe(data => {
  //       this.ServicesData = data['data']

  //       setTimeout(() => {

  //         if(data['count']>=2){
  //           this.ServiceOption = {
  //             loop: (data['data'].length>3) ? true: false,
  //             margin: 30,
  //             dots: false,
  //             nav: true,
  //             autoplay: true,
  //             autoplayHoverPause: true,
  //             navText: [
  //               "<i class='bx bx-chevron-left'></i>",
  //               "<i class='bx bx-chevron-right'></i>"
  //             ],
  //             mouseDrag: true,
  //             touchDrag: true,
  //             pullDrag: true,
  //             navSpeed: 400,
  //             responsive: {
  //               0: {
  //                 items: 1
  //               },
  //               576: {
  //                 items: 1
  //               },
  //               768: {
  //                 items: 3
  //               },
  //               1200: {
  //                 items: 3
  //               }
  //             }
  //           }
  //         }else{
  //           this.ServiceOption = {
  //             loop: (data['data'].length>3) ? true: false,
  //             margin: 30,
  //             dots: false,
  //             nav: true,
  //             autoplay: true,
  //             autoplayHoverPause: true,
  //             navText: [
  //               "<i class='bx bx-chevron-left'></i>",
  //               "<i class='bx bx-chevron-right'></i>"
  //             ],
  //             mouseDrag: true,
  //             touchDrag: true,
  //             pullDrag: true,
  //             navSpeed: 400,
  //           }
  //         }

         
  //       }, 500);
  //       console.log(this.ServicesData, "dhbcj")
       
  //     })
  // }

  // getBlogData() {
  //   this.apiService.getNewsAndUpdates(1, 9, '', 'desc', ' AND IS_ACTIVE=1')
  //     .subscribe(data => {
  //       this.BlogData = data['data']
  //       console.log(this.BlogData, "Blogs")
  //       setTimeout(() => {

  //         if(data['count']>=2){
  //           this.BlogOption = {
       
  //             loop:(data['data'].length>3) ? true: false,
  //             margin: 30,
  //             dots: false,
  //             nav: true,
  //             autoplay: true,
  //             autoplayHoverPause: true,
  //             navText: [
  //               "<i class='bx bx-chevron-left'></i>",
  //               "<i class='bx bx-chevron-right'></i>"
  //             ],
  //             mouseDrag: true,
  //             touchDrag: true,
  //             pullDrag: true,
  //             navSpeed: 400,
  //             autoWidth: true,
  //             responsive: {
  //               0: {
  //                 items: 1
  //               },
  //               576: {
  //                 items: 1
  //               },
  //               768: {
  //                 items: 3
  //               },
  //               1200: {
  //                 items: 3
  //               }
  //             }
  //           }
  //         }else{
  //           this.BlogOption = {
       
  //             loop:(data['data'].length>3) ? true: false,
  //             margin: 30,
  //             dots: false,
  //             nav: true,
  //             autoplay: true,
  //             autoplayHoverPause: true,
  //             navText: [
  //               "<i class='bx bx-chevron-left'></i>",
  //               "<i class='bx bx-chevron-right'></i>"
  //             ],
  //             mouseDrag: true,
  //             touchDrag: true,
  //             pullDrag: true,
  //             navSpeed: 400,
  //             autoWidth: true,
  //           }
  //         }
  //       }, 500);
  //       console.log(this.ServicesData, "dhbcj")
        
  //     })
  // }

  getIdOfBlog(event) {
    // console.log(event,"ser");
    localStorage.setItem("BlogssId", event);
  }

  getIdOfService(event){
    console.log(event,"ser");
    localStorage.setItem("ServiceId",event);
      }
    

}


