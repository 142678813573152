import { Component, HostListener } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {

  ServicesData:any=[]
  serviceUrl = appkeys.retriveimgUrl
  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
   }
  constructor(public apiService: ApiService ) { 
 
      this.apiService.getProjects(0, 0, '', 'desc', ' AND ID=' +localStorage.getItem("ProjectId"))
        .subscribe(data => {
          this.ServicesData = data['data'][0]
          console.log(this.ServicesData,"services Detail")
          // this.loaded = true;
        })




        this.getScreenSize(event);
        if (this.scrWidth <= 500) 
        { 
         this.show50 = true;
       }else{
         this.show50 = false;
       }
  }


    
  projectId = localStorage.getItem("ProjectId");

  getI(event:any){
    this.projectId=event;
    
  }

  ngOnInit(): void {
    this.getServisesData();
  }

  ServicesData1:any=[];

  getServisesData() {
    this.apiService.getProjects(1, 8, '', 'desc', 'AND IS_ACTIVE=1')
      .subscribe(data => {
        this.ServicesData1 = data['data']
        console.log(this.ServicesData1,"services")
        // this.loaded = true;
      })
  }

  getIdOfService(event){
// console.log(event,"ser");
localStorage.setItem("ProjectId",event);
this.apiService.getProjects(0, 0, '', 'desc', ' AND ID=' +localStorage.getItem("ProjectId"))
.subscribe(data => {
  this.ServicesData = data['data'][0]
  console.log(this.ServicesData,"services Detail")
  // this.loaded = true;
})

  }

}
