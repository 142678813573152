import { Injectable } from '@angular/core';
// import { http } from '@angular/common/http';
import {Observable } from 'rxjs';


// import { ToastrService } from 'ngx-toastr';


import { appkeys } from 'src/app/app.constant';
// import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';



@Injectable({
	providedIn: 'root'
})

export class ApiService {


	Baseurl= appkeys.baseUrl;
	url= appkeys.url;
	clientId=1;

	baseUrl = appkeys.baseUrl;
  
	httpHeaders = new HttpHeaders();
	  options = {
	  headers: this.httpHeaders
	};
	httpHeaders1 = new HttpHeaders();
	options1 = {
	headers: this.httpHeaders1
  };

  gmUrl = appkeys.gmUrl

  
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	fileExtension = '.xlsx';

	constructor(public httpClient:HttpClient,
         private toastrService: ToastrService,
         public cookie:CookieService) {



		if (this.cookie.get('deviceId') === '' || this.cookie.get('deviceId') === null) {

			var deviceId = this.randomstring(16);
			this.cookie.set('deviceId', deviceId.toString(), 365, '/', "", true, "None");
		}
		this.httpHeaders = new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
			'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
			'applicationkey': 'pylZOIhUYpbzcnwz',
			'deviceid': this.cookie.get('deviceId'),
			'supportkey': this.cookie.get('supportKey'),
			'token': this.cookie.get('token'),
			'sessionkey': this.cookie.get('sessionKey')

		});
		this.options = {
			headers: this.httpHeaders
		};
	}




    randomstring(L) {
        var s = '';
        var randomchar = function () {
            var n = Math.floor(Math.random() * 62);
            if (n < 10) return n; //1-10
            if (n < 36) return String.fromCharCode(n + 55); //A-Z
            return String.fromCharCode(n + 61); //a-z
        }
        while (s.length < L) s += randomchar();
        return s;
    }







 
  //Website Banner Master
	
	getAllwebsiteBanner (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
	  var data = {
		pageIndex: pageIndex,
		pageSize: pageSize,
		sortKey: sortKey,
		sortValue: sortValue,
		filter: filter
	  };
	  this.httpHeaders = new HttpHeaders({
		'Content-Type': 'application/json',
		'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		'applicationkey': 'pylZOIhUYpbzcnwz',
		'deviceid':this.cookie.get('deviceId'),
		'supportkey':this.cookie.get('supportKey'),
		'Token': this.cookie.get('token'),
		  });
		  this.options = {
		headers: this.httpHeaders
	  };
	  return this.httpClient.post<any>(this.url + "websitebannermaster/get", JSON.stringify(data), this.options);
	}
	

	//About
  
	getAbout(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "aboutmaster/get", JSON.stringify(data), this.options);
	  
	  
	  }
	

	  //About Company

	getCompanyProfile(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "companyprofile/get", JSON.stringify(data), this.options);
	  
	  
	  }

	  //Get Contact Info

	  getContactInfo(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "contactinfo/get", JSON.stringify(data), this.options);
	  
	  
	  }


	  createcontact(role: any): Observable<any> {
		role.CLIENT_ID=this.clientId;
		this.httpHeaders = new HttpHeaders({
				'Content-Type': 'application/json',
				'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
				'applicationkey': 'pylZOIhUYpbzcnwz',
				'deviceid':this.cookie.get('deviceId'),
				'supportkey':this.cookie.get('supportKey'),
				'Token': this.cookie.get('token'),
				  });
				  this.options = {
				headers: this.httpHeaders
			  };
		return this.httpClient.post<any>(this.url + "userContactUs/create", JSON.stringify(role), this.options);
	
	  }

	  //Get Services
	  getServices(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "serviceMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }


	  
	  //Get Committy
	  getCommeti(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "commiteMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }

  
	  //Get News
	  getNewsAndUpdates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "newsUpdate/get", JSON.stringify(data), this.options);
	  
	  
	  }

	   //Get Projects
	   getProjects(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "projectMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }

    
	  createEnquiryMaster(role:any) : Observable<any> {
		role.CLIENT_ID=this.clientId;
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "enquiryMaster/create", JSON.stringify(role), this.options);
	  }

	  getCountryMaster (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "countryMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }

	  getGalleryMaster (pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "photoMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }
	

	  getallAlbums(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "albummaster/get", JSON.stringify(data), this.options);
	  
	  
	  }

	  getTestmonials(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "testimonials/get", JSON.stringify(data), this.options);
	  
	  
	  }

	  getallVideoAlbums(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "videoAlbumMAster/get", JSON.stringify(data), this.options);
	  
	  
	  }

	  getVideoMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
  
		var data = {
		  pageIndex: pageIndex,
		  pageSize: pageSize,
		  sortKey: sortKey,
		  sortValue: sortValue,
		  filter: filter
		};
		this.httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'apikey': '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6',
		  'applicationkey': 'pylZOIhUYpbzcnwz',
		  'deviceid':this.cookie.get('deviceId'),
		  'supportkey':this.cookie.get('supportKey'),
		  'Token': this.cookie.get('token'),
			});
			this.options = {
		  headers: this.httpHeaders
		};
		return this.httpClient.post<any>(this.url + "videoMaster/get", JSON.stringify(data), this.options);
	  
	  
	  }
  
}