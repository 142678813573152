<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Enquiry.png);background-repeat: no-repeat;
background-size: 100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Online Enquiry</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>Enquiry</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Enquiry.png);background-repeat: no-repeat;
background-size: 100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Online Enquiry</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>Enquiry</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->


<!-- End Conatct Info -->
<br />
<br />
<!-- Contact Area -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Online Enquiry</span> -->
            <h3>Post Your Valuable Enquiry</h3>
            <br />
            <span style="color:black; line-height:25px;margin-bottom: 0px;font-size: 15px;">Fill in the form below and get detailed information about the service you are interested in. You will get instant access to Gheware Financial Services.</span>
        </div>

        <div class="contact-form">
            <form id="contactForm" #myform="ngForm">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <label style="font-size: 15px;
                        font-weight: 500;">Name <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" name="full_name" class="form-control" id="full_name" 
                            required placeholder=" Enter Full Name" autocomplete="off"
                            [pattern]=" namepattern" (keypress)="alphaOnly($event)" [(ngModel)]="enquiry.FULL_NAME">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Company Name <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" name="company_name" class="form-control" id="company_name" 
                            required placeholder="Enter Company Name" autocomplete="off"
                           (keypress)="alphaOnly($event)" [(ngModel)]="enquiry.COMPANY_NAME">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Mobile Number <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" id="phone_number" 
                            required placeholder="Enter Mobile Number" autocomplete="off"
                            [pattern]="mobpattern"  maxlength="10"
                          (keypress)="omit($event)" [(ngModel)]="enquiry.MOBILE_NO">
                        </div>
                    </div>


                    <div class="col-md-6 col-md-6">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Email Id <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" id="email"
                             required placeholder="Enter Email ID" autocomplete="off"
                             [pattern]="emailpattern" [(ngModel)]="enquiry.EMAIL_ID">
                        </div>
                    </div>


                    <div class="col-md-6 col-md-6">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Telephone Number </label>
                        <div class="form-group">
                            <input type="text" name="tel_number" class="form-control" id="tel_number"
                             required placeholder="Enter Telephone Number" autocomplete="off"
                           maxlength="10" (keypress)="omit($event)" [(ngModel)]="enquiry.TELEPHONE_NO">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Fax Number</label>
                        <div class="form-group">
                            <input type="text" name="fax_number" class="form-control" id="fax_number" 
                            required placeholder="Enter Fax Number" autocomplete="off"
                            maxlength="10"(keypress)="omit($event)" [(ngModel)]="enquiry.FAX_NO">
                        </div>
                    </div>

                  
                    <div class="col-md-12 col-md-12">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Address <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <textarea type="text" name="msg_address" id="msg_address" class="form-control" style="min-height: 70px;;max-height: 70px;"
                             required placeholder="Enter Address" autocomplete="off"
                            [(ngModel)]="enquiry.ADDRESS" cols="30" rows="2"></textarea>
                           
                        </div>
                    </div>

                 <div class="col-md-4 col-md-4">
                    <label  style="font-size: 15px;
                    font-weight: 500;">Country <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" list="datalistOptions" name="country" class="form-control"  required
                             placeholder="Select Country"  [(ngModel)]="COUNTRY_ID" (ngModelChange)="countryMap(COUNTRY_ID)"
                             autocomplete="off">  <i class="fas fa-arrow-down"></i>
                    <datalist id="datalistOptions">
                            <option *ngFor="let role of details" [value]="role.NAME">{{role.NAME}}</option>
 
                    </datalist>

                        </div>
                    </div>

                    <div class="col-md-4 col-md-4">
                        <label  style="font-size: 15px;
                        font-weight: 500;">City <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" name="city" class="form-control" id="city" 
                            required placeholder="Enter City"  autocomplete="off"
                             (keypress)="alphaOnly($event)" [(ngModel)]="enquiry.CITY">
                        </div>
                    </div>

                    <div class="col-md-4 col-md-4">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Postal Code <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <input type="text" name="Postal_code" class="form-control" id="Postal_code"
                             required placeholder="Enter Postal code" autocomplete="off"
                            (keypress)="omit($event)" [(ngModel)]="enquiry.POSTAL_CODE" minlength="6" maxlength="6">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <label  style="font-size: 15px;
                        font-weight: 500;">Enquiry Details <span style="color: red;">*</span></label>
                        <div class="form-group">
                            <textarea name="message" id="message" class="form-control" cols="30" rows="2" style="min-height: 70px;;max-height: 70px;"
                             required placeholder="Write your Enquiry Details..." [(ngModel)]="enquiry.ENQUIRY_DETAILS"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-2 ">  
                    </div>

                    <div class="col-lg-4 col-md-4 text-center" *ngIf="!isSpinning">
                        <button class="bannerBtn mb-2" (click)="save()"  style="width: 175px">Send Enquiry</button>
                    </div>

                    <div class="col-lg-4 col-md-4 text-center" *ngIf="isSpinning" style="text-align: center;margin-top: 4px;">
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 text-center">
                        <button type="button" class="bannerBtn" (click)="cancel()"  style="width: 175px">Cancel</button>
                    </div>

                    
                    <div class="col-lg-2 col-md-2 ">  
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Contact Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
