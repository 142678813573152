<!-- Page banner Area -->

    <div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Service.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
        <div class="d-table cardWid" >
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-content" style="margin: 80px;">
                        <h2>Our Services</h2>
                        <ul>
                            <li><a routerLink="/home-three">Home</a></li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
    <div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Service.png);background-repeat: no-repeat;
    background-size:100% 100%;background-color: orange;
    margin-top: 113.5px;height: auto;">
        <div class="d-table cardWid" >
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-content" style="margin: 60px 20px 60px 20px;
                    padding: 20px 20px;">
                        <h2>Our Services</h2>
                        <ul>
                            <li><a routerLink="/home-three">Home</a></li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100" style="padding-top: 70px;">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>We provide professional services for inbound and outbound business setup</h3>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let data of ServicesData">
                <div class="service-card-two">
                    <img *ngIf="ServicesData!=undefined" src="{{serviceUrl}}serviceImages/{{data.IMG_URL}}" 
                    height="215" width="366"  alt="image">
                    <div class="service-caption" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                    height: 265px;">
                        <h3 *ngIf="ServicesData!=undefined" style="min-height: 74px;font-size: 19px;">{{data.TITLE}}</h3>
                        <p *ngIf="ServicesData!=undefined" class="limit">{{data.DESCRIPTION}}</p>
                        <a (click)="getIdOfService(data.ID)" routerLink="/services-details"  class="default-btn-two"
                        style="padding: 5px 8px;">Read More</a>
                    </div>
                </div>
            </div>
<!-- 
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services2.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Ocean Freight</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services3.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Air Freight</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services4.jpg" alt="image">
                    <div class="service-caption">
                        <h3>B2B Exchange</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services5.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Home Shipping</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services6.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Office Moving</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
             -->
            <!-- <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div> -->
            <div class="col-lg-12 col-md-12" *ngIf="totalcount>9">
                <ul class="pagination">
                    <!-- <li class="page-item"><a class="page-link" ><span aria-hidden="true">&laquo;</span></a></li> -->
                    <li class="page-item " >
                        <a class="page-link" style="font-size: 11px;"  (click)="previousPage()">
                            
                            <<
                        </a>
                      </li>
                    <li class="page-item active" *ngFor="let p of pages"
                    [ngClass]="{'active': p === currentPage}" style="cursor: pointer;">
                        <a class="page-link" (click)="pagination(p)">{{p}}</a>
                    </li>
                    <li class="page-item" >
                        <a class="page-link" style="font-size: 11px;" (click)="nextPage()" >
                            
                            >>
                        </a>
                      </li>
                   
                    <!-- <li class="page-item"><a class="page-link" (click)="pagination(p)">{{p}}</a></li> -->
                  
                    <!-- <li class="page-item"><a class="page-link" ><span aria-hidden="true">&raquo;</span></a></li> -->
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<!-- <div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2>Best <span>Logistic Service</span> Solution Near you</h2>
                    <p>At vero eos et et iusto odio ducimus qui blanditiis praesentium animi voluptatum deleniti atque corrupti  dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                </div>
                <a routerLink="/about" class="default-btn-one">Read More</a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="assets/img/services/services7.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Best Logistic Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->