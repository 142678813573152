<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/GhewareLogo.jpg" width="145" height="41" alt="logo">
                    </div>
                    <p style="text-align: justify;">We provide professional services for inbound and outbound business setup.
                       
                         We take care of investment banking & financial planning requirements by providing authentic advice.
                        </p>
                    <ul class="footer-socials">
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.FB_LINK}}" target="_blank"><i class='bx bxl-facebook facebookIcon'></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.TW_LINK}}" target="_blank"><i class='bx bxl-twitter twitterIcon' ></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.SOCIAL_4_LINK}}" target="_blank"><i class='bx bxl-linkedin linkedinIcon'></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.INSTA_LINK}}" target="_blank"><i class='bx bxl-instagram instagramIcon'></i></a></li>

                        <!-- <li><a *ngIf="ContactData!=undefined" href="{{ContactData.FB_LINK}}" target="_blank"><img style="width: 20px; margin:5px;background-color: white;" src="../../../../assets/img/Facebook-Icon.png" alt=""></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.TW_LINK}}" target="_blank"><img style="width: 20px; margin:5px;background-color: white;" src="../../../../assets/img/Twitter-Icon.png" alt=""></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.SOCIAL_4_LINK}}" target="_blank"><img style="width: 20px; margin:5px; background-color: white;border-radius: 0px;" src="../../../../assets/img/LinkedIn_Icon.png" alt=""></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.INSTA_LINK}}" target="_blank"><img style="width: 20px; margin:5px;background-color: white;border-radius: 5px;" src="../../../../assets/img/Instagram-Icon.png" alt=""></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>
            
                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <!-- <li><a routerLink="/">Cost calculator</a></li> -->
                        <li><a routerLink="/blog">Latest News</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>Support</h3>
            
                    <ul class="footer-text">
                        <!-- <li><a routerLink="/faq">FAQ’s</a></li> -->
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/">Community</a></li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li *ngIf="ContactData!=undefined"><i class='bx bxs-location-plus'></i> {{ContactData.ADDRESS_DETAILS}}</li>
                        <li *ngIf="ContactData!=undefined"><i class='bx bxs-envelope'></i> <a href="mailto:vvgheware@yahoo.com">{{ContactData.EMAIL_ID}}</a></li>
                        <li *ngIf="ContactData!=undefined"><i class='bx bxs-phone'></i> <a href="tel:+91-233-2373506">{{ContactData.CONTACT_NO}}</a></li>
                        <!-- <li><i class='bx bxs-envelope'></i> <a href="mailto:contact@floza.com">contact@floza.com</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p>Copyright © {{year}} <a style="color:orange; font-weight: 500;">Gheware Financial Services </a>

        <!-- <p>© Gheware Financial Services is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p> -->
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->