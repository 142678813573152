<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>Video Gallery</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>Video Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="show50" class="page-banner" style="background: url(assets/img/Header/Gallery.png);background-repeat: no-repeat;
background-size:100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid" >
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>Video Gallery</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>Video Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- End Page banner Area -->

<!--Album area-->


<!-- Gallery Area -->
<div class="gallery-area  pb-70" style="padding-top: 39px;">
    <div class="container">
        <div class="gallery-title">
            <h3>Our Video Gallery</h3>
        </div>

        <div class="col-md-12" class="gallery">
            <ul class="gallery-filter list-unstyled list-inline text-center" style="margin-bottom: 40px;">
            
                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor1 == 1 ? {'background':'#f7941d'} : {'background':'#8732de'}"
                (click)="getall()">
                 <span>All</span></li>


                <li class="default-btn-one mr-3" style="margin-top: 10px;margin-right: 10px;min-width: 130px;padding: 9px 3px;
                cursor: pointer;" 
                [ngStyle]="showcolor == j ? {'background':'#f7941d'} : {'background':'#8732de'}"
                 *ngFor="let x of albums;let i=index; let j=index;" (click)="get(x.ID)" (click)=" getI(i)">
                 <span *ngIf="albums!=undefined">
                    {{x.TITLE}}
                 </span></li>
                
             
            </ul>
        </div> 

        <div class="row" >
            <div *ngIf="Videocount==0" style="margin-top: 55px;">
                <h3 class="text-center" style="font-size: larger;color: red;">Data Not Found..!</h3>
          </div>
            <div class="col-lg-4 col-md-6" *ngFor="let x of videos;let i=index; let j=index">


                    <div class="floza-gallery" >
                        <div>
                        
                         <!-- <img src="{{serviceUrl}}video/{{x.VIDEO_URL}}" alt="image" 
                         height="191" width="366">
                        </div> -->

                        <video  width="366" (playing)="onPlayingVideo($event)"
                        height="191" controls   >
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/mp4">
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/webm">
                        <source *ngIf="videos!=undefined" src="{{serviceUrl}}video/{{x.VIDEO_URL}}"
                            type="video/ogg">
                    </video>
                   


                        <!-- <youtube-player [videoId]="x.VIDEO_URL"
                        [height]="191" 
                        [width]="366"> 
        
                        </youtube-player> -->
                         <!-- <div class="caption">
                             <div class="d-table">
                                 <div class="d-table-cell">
                                    
                                     <a href="{{serviceUrl}}photos/{{x.IMG_URL}}"><i class='bx bx-show-alt'></i></a>
                                 </div>
                             </div>
                         </div> -->
                     <!-- </div> -->

                   <!-- <div>
                
                    <div class="col-lg-4 col-md-4">
                        <div class="popup-video">
                           
                           
                        <img src="{{serviceUrl}}videos/{{x.VIDEO_URL}}" alt="image" 
                        height="191" width="366">
                           
                        </div>
                    </div>
                    
                   </div> -->
                   <!-- <a  href="https://www.youtube.com/watch?v=p1GmFCGuVjw" height="191" width="366"
                    class="popup-youtube">
                        <img style="width: 100%;" 
                        href="https://www.youtube.com/watch?v=p1GmFCGuVjw" height="191" width="366"
                        class="popup-youtube"
                   >
                  
                    </a> -->

                    <!-- <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                               
                                <a href="{{serviceUrl}}video/{{x.VIDEO_URL}}" class="popup-youtube">
                                    <i class="bx bx-play whiteText"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <i class='bx bx-play  play-icon'></i>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <h5 style="text-align: center;margin-bottom: 20px;color:#8732DE;">{{x.VIDEO_NAME}}</h5>
            <!-- <div *ngIf="Albumcount==0" style="margin-top: 55px;">
                <h3 class="text-center" style="font-size: larger;color: red;">Album Not Found..!</h3>
          </div> -->

           
            
            <!-- <div *ngFor="let x of videos">
            <youtube-player [videoId]="x.VIDEO_URL"
                    [height]="191" 
                    [width]="366"> 
    
    </youtube-player>
</div> -->



            <!-- <div class="col-lg-4 col-md-4 text-center" *ngIf="isSpinning" style="margin-top: 4px;">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only"></span>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery2.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery2.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery3.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery3.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery4.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery4.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery5.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery5.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/gallery6.jpg" alt="image">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/gallery6.jpg"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->