export const appkeys = {
    // // //TESTING
    gmUrl: 'https://gm.tecpool.in:8079/',
    baseUrl: 'http://gfsadmin.uvtechsoft.com:9756/',
    url: 'http://gfsadmin.uvtechsoft.com:9756/',
    imgUrl: 'http://gfsadmin.uvtechsoft.com:9756/api/upload/',
    retriveimgUrl: 'http://gfsadmin.uvtechsoft.com:9756/static/',

    // http://gfsadmin.uvtechsoft.com:9756
    
   
    // //LOCAL
    // gmUrl: 'https://gm.tecpool.in:8078/',
    // baseUrl: 'http://192.168.29.20:3000/',
    // url: 'http://192.168.29.20:3000/',
    // imgUrl: 'http://192.168.29.20:3000/api/upload/',
    // retriveimgUrl: 'http://192.168.29.20:3000/static/',



    // Local 
    // Api Key : 68h3u1OxG6We2UnRD4F3IratYZHQ5hRB
    // application key : AfIpESwBr5eHp7w3



    // Testing
    // Api Key = '0xfIOhXhhi73Hatk37kuAC7eGhYaUFz6'
    // Application Key = 'pylZOIhUYpbzcnwz'


  };