import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/Services/api.service';
import { contact } from 'src/modal/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  ContactData:any=[];
  isSpinning=false;
  namepattern = /^([a-zA-Z'-.]+ [a-zA-Z'-.]+ [a-zA-Z'-.]+)$/
  mobpattern = /^[7-9]\d{9}$/
  emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  contact: contact = new contact();


  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
   }
  
  constructor(public apiService:ApiService, public tost:ToastrService) { 
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
  }



  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  myform: NgForm
  getid: any;

  
  ngOnInit(): void {
    this.getContactInfo();
  }

  getContactInfo() {
    this.apiService.getContactInfo(1, 2, '', 'desc', '')
      .subscribe(data => {
        this.ContactData = data['data'][0]
        console.log(this.ContactData,"dhbcj")
      })
  }



  save() {

    if ((this.contact.NAME == '' || this.contact.NAME == undefined)
      && (this.contact.EMAIL_ID == '' || this.contact.EMAIL_ID == undefined)
      && (this.contact.DESCRIPTION == '' || this.contact.DESCRIPTION == undefined)
      && (this.contact.MOBILE_NO == undefined)
      && (this.contact.SUBJECT == '' || this.contact.SUBJECT == undefined)) {
      this.tost.error('Please Fill All Required Fields');

    } else if (this.contact.NAME == '' || this.contact.NAME == undefined) {
      this.tost.error('Please Enter Full Name');
    }
    else if (!this.namepattern.test(this.contact.NAME)) {
      this.tost.error('Please Enter Valid Name')
    }

    else if (this.contact.EMAIL_ID == '' || this.contact.EMAIL_ID == undefined) {
      this.tost.error('Please Enter Email ID')
    }
    else if (!this.emailpattern.test(this.contact.EMAIL_ID)) {
      this.tost.error('Please Enter Valid Email ID')
    }
    else if (this.contact.MOBILE_NO == undefined) {
      this.tost.error('Please Enter Mobile Number')
    }
    else if (!this.mobpattern.test(this.contact.MOBILE_NO)) {
      this.tost.error('Please Enter Valid Mobile Number ')
    }
    else if (this.contact.DESCRIPTION == '' || this.contact.DESCRIPTION == undefined) {
      this.tost.error('Please Enter Address')
    } 
    
    else if (this.contact.SUBJECT == '' || this.contact.SUBJECT == undefined) {
      this.tost.error('Please Write Your Message')
    }
    else {
      this.isSpinning=true;
      this.apiService.createcontact(this.contact).subscribe((successCode) => {
        if (successCode['code'] == '200') {

          console.log(this.contact);
          this.tost.success('Contact Information Saved Successfully..');
          this.cancel();
          this.isSpinning=false;
        } else {

          this.tost.error('Failed To Save Contact Information..');
          this.isSpinning=false;
          this.cancel();
        }
      });

    }

  }

  
 cancel(){
   this.contact=new contact();
  }

}
