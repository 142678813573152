import { Component, HostListener, OnInit } from '@angular/core';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  TeamsData:any=[]
  serviceUrl = appkeys.retriveimgUrl
  
  scrHeight: any; 
scrWidth: any;
 show50: boolean = false;

 
 @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
 {
 this.scrHeight = window.innerHeight; 
 this.scrWidth = window.innerWidth;
  console.log(this.scrHeight, this.scrWidth);
 }

  constructor(public apiService: ApiService ) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }

  ngOnInit(): void {
    this.getTeamsData()
  }


  getTeamsData() {
    this.apiService.getCommeti(0, 0, '', 'desc', ' AND IS_ACTIVE=1')
      .subscribe(data => {
        this.TeamsData = data['data']
        console.log(this.TeamsData,"Teams")
        // this.loaded = true;
      })
  }

  getIdOfTeams(event){
    console.log(event,"ser");
    localStorage.setItem("TeamsId",event);
      }
    
}
