<!-- Page banner Area -->
<div *ngIf="!show50" class="page-banner" style="background: url(assets/img/Header/Blog.png);background-repeat: no-repeat;
background-size: 100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 80px;">
                    <h2>News</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="show50"  class="page-banner" style="background: url(assets/img/Header/Blog.png);background-repeat: no-repeat;
background-size: 100% 100%;background-color: orange;
margin-top: 113.5px;height: auto;">
    <div class="d-table cardWid">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content" style="margin: 60px 20px 60px 20px;
                padding: 20px 20px;">
                    <h2>News</h2>
                    <ul>
                        <li><a routerLink="/home-three">Home</a></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Blog Area -->
<div class="blog-area-two ptb-100" style="padding-top: 70px;">
    <div class="container">
        <div class="section-title">
            <span>Our News</span>
            <h3>Read Our Latest News</h3>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let data of BlogData">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details" (click)="getIdOfService(data.ID)">
                        <img *ngIf="BlogData!=undefined" src="{{serviceUrl}}newsUpdateImages/{{data.IMAGE_URL}}"
                        height="215" width="366"  alt="image">
                    </a>
                    <div class="blog-text" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                    height: 260px;">
                        <!-- <span class="meta-tag">Delivery</span> -->
                        <div *ngIf="BlogData!=undefined" class="date">{{data.CREATED_MODIFIED_DATE | date}}</div>
                        <h3 *ngIf="BlogData!=undefined" style="min-height: 60px;font-size: 19px;"><a routerLink="/blog-details" (click)="getIdOfService(data.ID)">{{data.TITLE}}</a></h3>
                        <div *ngIf="BlogData!=undefined"
                        style="min-height: 50px;" class="mb-20 limit" [innerHTML]="data.DESCRIPTION"></div>
                        <a routerLink="/blog-details" (click)="getIdOfService(data.ID)" class="default-btn-two"
                        style="padding: 5px 8px">Read More</a>
                    </div>
                </div>
                
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">23 August, 2020</div>
                        <h3><a routerLink="/blog-details">Air cargo may become short-term solution</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog11.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">24 August, 2020</div>
                        <h3><a routerLink="/blog-details">We introduce new boat and flight service</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog12.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">25 August, 2020</div>
                        <h3><a routerLink="/blog-details">Goods will be contain in certified safe</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog13.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">26 August, 2020</div>
                        <h3><a routerLink="/blog-details">Introduce new boat service in this spring</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog10.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">27 August, 2020</div>
                        <h3><a routerLink="/blog-details">Our customer around the world satisty with it</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div> -->
            
            <!-- Pagination -->
            <div class="col-lg-12 col-md-12" *ngIf="totalcount>12">
                <ul class="pagination">
                    <!-- <li class="page-item"><a class="page-link" ><span aria-hidden="true">&laquo;</span></a></li> -->

                    <li class="page-item " >
                        <a class="page-link" style="font-size: 11px;"  (click)="previousPage()">
                            
                            <<
                        </a>
                      </li>
                    <li class="page-item active" *ngFor="let p of pages"
                    [ngClass]="{'active': p === currentPage}" style="cursor: pointer;">
                        <a class="page-link" (click)="pagination(p)">{{p}}</a>
                    </li>
                    <li class="page-item" >
                        <a class="page-link" style="font-size: 11px;" (click)="nextPage()" >
                            
                            >>
                        </a>
                      </li>

                    <!-- <li class="page-item active" *ngFor="let p of pages" style="cursor: pointer;">
                        <a class="page-link" (click)="pagination(p)">{{p}}</a>
                    </li> -->
                    <!-- <li class="page-item"><a class="page-link" (click)="pagination(p)">{{p}}</a></li> -->
                  
                    <!-- <li class="page-item"><a class="page-link" ><span aria-hidden="true">&raquo;</span></a></li> -->
                </ul>
            </div>

            <!-- <div class="col-lg-12 col-md-12 col-sm-12">

               <div class="pagination-area text-center">
                
             <span class="page-numbers current" aria-current="page" *ngFor="let p of pages">
                
           <span (click)="pagination(p)">{{p}}</span>
                
               </span>
                
 <i class='bx bx-chevron-right'></i> </div></div> -->




        </div>
    </div>
</div>
<!-- End Blog Area -->