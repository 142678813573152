import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { appkeys } from 'src/app/app.constant';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  ServicesData:any=[]
  serviceUrl = appkeys.retriveimgUrl
  totalcount:any=0
  pageSize:any=9
  pages:any=[]
  scrHeight: any; 
  scrWidth: any;
   show50: boolean = false;
  
   
   @HostListener('window:resize', ['$event']) getScreenSize(event: any) 
   {
   this.scrHeight = window.innerHeight; 
   this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
   }
  constructor(public apiService: ApiService ) {
    this.getScreenSize(event);
    if (this.scrWidth <= 500) 
    { 
     this.show50 = true;
   }else{
     this.show50 = false;
   }
   }

  ngOnInit(): void {
    this.getServisesData();
  }
  getServisesData() {
    this.apiService.getServices(1, 9, '', 'desc', 'AND IS_ACTIVE=1')
      .subscribe(data => {
        this.ServicesData = data['data']
        this.totalcount = data['count'];
        this.totalPages = Math.ceil(this.totalcount / this.pageSize);
        for (var i = 0; i <= (this.totalcount / this.pageSize); i++) {
         this.pages.push(i + 1);
        }
        // this.loaded = true;
      })
  }



  pagination(p: any) {
    this.apiService.getServices(p, this.pageSize, '', 'desc', 'AND IS_ACTIVE=1').subscribe(data => {
       
   if (data.code == "200") {
     this.ServicesData = [...[], ...data['data']];
     this.currentPage = p;;
     } 
   else { 
    this.ServicesData = []
    this.totalPages = 1;
    }
   }) 
   }

   currentPage = 1;
   totalPages = 1;
 
   previousPage() {
     if (this.currentPage > 1) {
       this.pagination(this.currentPage - 1);
     }
   }
   
   nextPage() {
  
    if (this.currentPage < this.totalPages) {
       this.pagination(this.currentPage + 1);
     }
   }

  getIdOfService(event){
// console.log(event,"ser");
localStorage.setItem("ServiceId",event);
  }



  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: { 0: { items: 1 } },
    nav: false
  }

}
