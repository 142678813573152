<!-- Header Area -->
<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li><a href="mailto:vvgheware@yahoo.com" *ngIf="ContactData!=undefined"><i class='bx bxs-envelope'></i>{{ContactData.EMAIL_ID}}</a></li>
                        <li><a href="tel:+91-233-2373506" *ngIf="ContactData!=undefined"><i class='bx bxs-phone-call'></i>{{ContactData.CONTACT_NO}}</a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <li class="mr-20"><a routerLink="/contact">Contact</a></li>
                        <!-- <li class="mr-20"><a routerLink="/">Career</a></li> -->
                        <li class="mr-20"><a routerLink="/blog">Latest News </a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.FB_LINK}}" target="_blank"><i class='bx bxl-facebook' style="background:#0672e1; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.TW_LINK}}" target="_blank"><i class='bx bxl-twitter' style="background:#38A1F3; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.SOCIAL_4_LINK}}" target="_blank"><i class='bx bxl-linkedin' style="background:#0284ca; color: white;"></i></a></li>
                        <li><a *ngIf="ContactData!=undefined" href="{{ContactData.INSTA_LINK}}" target="_blank"><i class='bx bxl-instagram' style="background:linear-gradient(to bottom, #833AB4, #cf4242, #FCAF45); color: white;"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/home-three">
                            <img src="assets/img/GhewareLogo.jpg" alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/home-three"><img src="assets/img/GhewareLogo.jpg" width="145"
                            height="41" alt="logo"></a>

                  
                <div *ngIf="buttonToggler">
                    <button (click)="buttonToggler = !buttonToggler" style="border: none;" class="navbar-toggler" type="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span style="color: #f7941d;" class="navbar-toggler-icon"></span>
                    </button>
                </div>
                   
                    <div *ngIf="!buttonToggler">
                        <button (click)="buttonToggler = !buttonToggler"
                            style="border: none; background-color: transparent;" class="navbar-toggler" type="button"
                            data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path
                                    d="M.646 15.354a.5.5 0 0 1-.708-.708L15.354.646a.5.5 0 0 1 .708.708L.646 15.354z" />
                                <path
                                    d="M15.354 15.354a.5.5 0 0 1-.708.708L.646.646a.5.5 0 0 1 .708-.708L15.354 15.354z" />
                            </svg>
                        </button>
                    </div>


                    <div class="collapse navbar-collapse " id="navbarNav">

                        <ul class="navbar-nav ms-auto">

                            <li class="nav-item "><a style="margin-left: 17px;" routerLink="/home-three"
                                    class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                
                             <!-- for Mobile view  -->

                            <li class="nav-item " *ngIf="!buttonToggler">
                                <a class="nav-link" (click)="AboutUsbtn = !AboutUsbtn" data-bs-toggle="collapse"
                                    href="#AboutusFields" role="button" aria-expanded="false"
                                    aria-controls="AboutusFilds">About Us

                                    <svg *ngIf="AboutUsbtn" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-plus pluseIcon" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>

                                    <svg *ngIf="!AboutUsbtn" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-dash dashIcon" viewBox="0 0 16 16">
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>

                                </a>
                            </li>
                             

                            <ul class="list-group collapse" id="AboutusFields" style="margin-left: 20px;">
                                <li class="nav-item"><a style="margin-left: 17px;" routerLink="/about" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Company
                                        Profile</a></li>
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Director Profile</a></li>
                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">News</a></li>
                            </ul>

                            <!-- end Mobile view  -->





                            <li class="nav-item" *ngIf="buttonToggler">
                                <a href="javascript:void(0)" class="nav-link">About Us <i
                                        class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu collapse">
                                    <li class="nav-item"><a routerLink="/about" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Company
                                            Profile</a></li>
                                    <li class="nav-item"><a routerLink="/team" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Director
                                            Profile</a></li>
                                    <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                                    <li class="nav-item"><a routerLink="/blog" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">News</a>
                                    </li>
                                </ul>
                            </li>


                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Services</a></li>
                            <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Projects</a></li>


                            <!-- end Mobile view  -->
                            <li class="nav-item " *ngIf="!buttonToggler">
                                <a class="nav-link" data-bs-toggle="collapse" href="#GalleryFields" role="button"
                                    aria-expanded="false" aria-controls="GalleryFields"
                                    (click)="GalleryIcon = !GalleryIcon">Gallery
                                    <svg *ngIf="GalleryIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-plus pluseIcon" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>

                                    <svg *ngIf="!GalleryIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-dash dashIcon" viewBox="0 0 16 16">
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>


                                </a>
                            </li>

                            <ul class="list-group collapse" id="GalleryFields" style="margin-left: 20px;">
                                <li class="nav-item"><a style="margin-left: 17px;" routerLink="/gallery"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Event Gallery</a></li>

                                <li class="nav-item"><a routerLink="/video-gallery" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Video
                                        Gallery</a></li>
                            </ul>

                            <!-- end Mobile view  -->



                            <li class="nav-item" *ngIf="buttonToggler">
                                <a href="javascript:void(0)" class="nav-link">Gallery <i
                                        class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/gallery" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Event
                                            Gallery</a></li>

                                    <li class="nav-item"><a routerLink="/video-gallery" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Video
                                            Gallery</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/enquiry" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Enquiry</a></li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>


    
    <!-- <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/home-three">
                            <img src="assets/img/GhewareLogo.jpg" alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/home-three"><img src="assets/img/GhewareLogo.jpg"
                        width="145" height="41" alt="logo"></a>

                      

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                           

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">About Us <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company Profile</a></li>
                            <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Director Profile</a></li>
                            <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                            <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a></li>
                                </ul>
                            </li>
                            
                           
                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                            <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Gallery <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Event Gallery</a></li>

                                    <li class="nav-item"><a routerLink="/video-gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Gallery</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/enquiry" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Enquiry</a></li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>


                           
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div> -->
</header>
<!-- End Header Area -->